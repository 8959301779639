import { Card, Col, Row } from "react-bootstrap";
import { bool, object } from "prop-types";

import Clickable from "/components/generics/clickable";
import Image from "next/image";
import Link from "next/link";
import dayjs from "dayjs";

const localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const GuideCard = ({ post, small, full }) => {
  GuideCard.propTypes = {
    post: object,
    small: bool,
    full: bool,
  };
  return (
    <Clickable force={1}>
      <Link passHref prefetch={false} href={`/conseils/${post.slug}`}>
        <a style={{ textDecoration: "none" }}>
          {small ? (
            <Card
              className="mx-0 my-2 shadow-sm text-black text-decoration-none border border-2 border-orange1 rounded"
              role="button"
              style={{ maxWidth: "700px" }}
            >
              <Row className="w-100 m-0 p-0">
                <Col
                  sm={3}
                  className="rounded-start"
                  style={
                    post.cover
                      ? {
                          backgroundImage: `url(${
                            !process.env.NEXT_PUBLIC_NOTION_EXPIRED_TIME
                              ? post.image.files[0].file.url
                              : "https://storage.googleapis.com/rakshi-notion-bucket/" +
                                post.id
                          })`,
                          backgroundSize: "auto 100%",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }
                      : null
                  }
                ></Col>
                <Col sm={9} className="mx-0 px-0">
                  <Card.Body className="p-3 d-flex flex-column">
                    <h3 className="fw-bold h5">{post.libele}</h3>
                    {post.description.length > 160 ? (
                      <p className="small mb-1">
                        {post.description.slice(
                          0,
                          post.description.slice(0, 160).lastIndexOf(" ")
                        )}
                        &nbsp;…
                      </p>
                    ) : (
                      <p className="small mb-1">{post.description}</p>
                    )}
                  </Card.Body>
                  <Card.Footer className="mx-0 bg-grey6 border-top border-orange3 border-1">
                    <Row>
                      <Col xs={10} className="text-center m-auto ps-0">
                        <p className="text-center mb-0 lead text-primary">
                          {post.tags.map((tag, index) => {
                            return (
                              <span key={index}>
                                {tag}
                                {index < post.tags.length - 1 ? " • " : null}
                              </span>
                            );
                          })}
                        </p>
                      </Col>
                    </Row>
                  </Card.Footer>
                </Col>
              </Row>
            </Card>
          ) : (
            <Card
              className={`m-2 shadow-sm border border-2 border-orange1 rounded ${
                full ? "w-100 text-black text-decoration-none" : "cardArticle"
              }`}
              role="button"
            >
              <Card.Header
                className={
                  "m-0 p-0 d-flex text-center justify-content-center overflow-hidden"
                }
              >
                {post.cover ? (
                  <Image
                    height={full ? 220 : 196}
                    width={full ? 500 : 350}
                    className="imageHeader m-auto"
                    src={
                      !process.env.NEXT_PUBLIC_NOTION_EXPIRED_TIME
                        ? post.image.files[0].file.url
                        : `https://storage.googleapis.com/rakshi-notion-bucket/${post.id}`
                    }
                    alt={post.title}
                  />
                ) : (
                  <div
                    style={
                      full
                        ? { height: "220px", width: "500px" }
                        : { height: "196px", width: "350px" }
                    }
                    className="bg-yellow2"
                  />
                )}
              </Card.Header>
              <Card.Body className="px-3 py-3 d-flex flex-column">
                <h3 className="fw-bold h5">{post.title}</h3>

                {post.description.length > 160 ? (
                  <p className="small mb-1">
                    {post.description.slice(
                      0,
                      post.description.slice(0, 160).lastIndexOf(" ")
                    )}
                    &nbsp;…
                  </p>
                ) : (
                  <p className="small mb-1">{post.description}</p>
                )}
              </Card.Body>
              <Card.Footer className="mx-0 bg-grey6 border-top border-orange3 border-1">
                <Row>
                  <Col xs={10} className="text-center ps-0 mx-auto">
                    <p className="text-center mb-0 text-primary">
                      {post.tags.map((tag, index) => {
                        return (
                          <span key={index}>
                            {tag}

                            {index < post.tags.length - 1 ? " • " : null}
                          </span>
                        );
                      })}
                    </p>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          )}
        </a>
      </Link>
    </Clickable>
  );
};

export default GuideCard;
