import { Carousel } from "react-bootstrap";
import GuideCard from "./guideCard";
import { array } from "prop-types";

const CarouselArticles = ({ posts }) => {
  CarouselArticles.propTypes = {
    posts: array,
  };

  return (
    <div>
      <Carousel
        interval={8000}
        controls={false}
        fade
        variant="dark"
        indicators={true}
        className="d-block d-md-none pb-5"
      >
        <Carousel.Item>
          <div className="d-flex flex-wrap justify-content-center">
            <GuideCard post={posts[0]} />
          </div>
        </Carousel.Item>
        {posts.length > 1 && (
          <Carousel.Item>
            <div className="d-flex flex-wrap justify-content-center">
              <GuideCard post={posts[1]} />
            </div>
          </Carousel.Item>
        )}
        {posts.length > 2 && (
          <Carousel.Item>
            <div className="d-flex flex-wrap justify-content-center">
              <GuideCard post={posts[2]} />
            </div>
          </Carousel.Item>
        )}
        {posts.length > 3 && (
          <Carousel.Item>
            <div className="d-flex flex-wrap justify-content-center">
              <GuideCard post={posts[3]} />
            </div>
          </Carousel.Item>
        )}
        {posts.length > 4 && (
          <Carousel.Item>
            <div className="d-flex flex-wrap justify-content-center">
              <GuideCard post={posts[4]} />
            </div>
          </Carousel.Item>
        )}
        {posts.length > 5 && (
          <Carousel.Item>
            <div className="d-flex flex-wrap justify-content-center">
              <GuideCard post={posts[5]} />
            </div>
          </Carousel.Item>
        )}
      </Carousel>

      <Carousel
        interval={8000}
        controls={true}
        fade
        variant="dark"
        indicators={true}
        className="d-none d-md-block d-xl-none pb-5"
      >
        <Carousel.Item>
          <div className="d-flex flex-wrap justify-content-center">
            {posts.slice(0, 2).map((post) => (
              <GuideCard key={post.id} post={post} />
            ))}
          </div>
        </Carousel.Item>
        {posts.length > 3 && (
          <Carousel.Item>
            <div className="d-flex flex-wrap justify-content-center">
              {posts.slice(2, 4).map((post) => (
                <GuideCard key={post.id} post={post} />
              ))}
            </div>
          </Carousel.Item>
        )}
        {posts.length > 5 && (
          <Carousel.Item>
            <div className="d-flex flex-wrap justify-content-center">
              {posts.slice(4, 6).map((post) => (
                <GuideCard key={post.id} post={post} />
              ))}
            </div>
          </Carousel.Item>
        )}
      </Carousel>

      <Carousel
        interval={8000}
        controls={posts.length > 4 ? true : false}
        fade
        variant="dark"
        indicators={true}
        className="d-none d-xl-block pb-5"
      >
        <Carousel.Item>
          <div className="d-flex flex-wrap justify-content-center">
            {posts.slice(0, 3).map((post) => (
              <GuideCard key={post.id} post={post} />
            ))}
          </div>
        </Carousel.Item>
        {posts.length > 4 && (
          <Carousel.Item>
            <div className="d-flex flex-wrap justify-content-center">
              {posts.slice(3, 6).map((post) => (
                <GuideCard key={post.id} post={post} />
              ))}
            </div>
          </Carousel.Item>
        )}
      </Carousel>
    </div>
  );
};

export default CarouselArticles;
